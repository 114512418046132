<template>
  <vue-apex-charts
    type="line"
    height="350"
    :options="config"
    :series="data.series"
    class="clickable"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "lineChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      config: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: "line",
          offsetX: -10,
          events: {
            markerClick: (e, chart, opts) => {
              this.chartClick(opts);
            },
          },
        },
        stroke: {
          curve: "smooth",
          width: [2, 2, 2, 2, 2, 2, 2, 2],
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        colors: this.data.colors,
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: this.data.colors,
            shadeIntensity: 1,
            type: "horizontal",
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
          categories: ["01", "05", "09", "13", "17", "21", "26", "31"],
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#A9A9A9"],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: "#e7eef7",
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    };
  },

  methods: {
    // GET ITEM WHEN CHART CLICKED
    chartClick(option) {
      this.$router.push("/mention");
    },
  },
};
</script>
