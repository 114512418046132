<template>
  <b-card no-body class="card-apply-job twitter">
    <b-card-header class="pb-1">
      <b-media no-body>
        <!-- HEADER MEDSOS NEWS -->
        <b-media-aside class="mr-1">
          <!-- ACCOUNT IMAGE -->
          <b-avatar
            size="42"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
          />
        </b-media-aside>

        <b-media-body class="align-items-center">
          <!-- ACCOUNT NAME -->
          <h5 class="mb-1 clickable" @click="$router.push('/detail-akun')">@pluviphilera</h5>

          <!-- ACCOUNT TYPE -->
          <div class="d-flex">
            <!-- TWITTER -->
            <div
              v-if="type == 'twitter'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Twitter'"
            >
              <i class="bi bi-twitter twitter-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Twitter</h6>
            </div>

            <!-- FACEBOOK -->
            <div
              v-if="type == 'facebook'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Facebook'"
            >
              <i class="bi bi-facebook facebook-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Facebook</h6>
            </div>

            <!-- INSTAGRAM -->
            <div
              v-if="type == 'instagram'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Instagram'"
            >
              <i class="bi bi-instagram instagram-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Instagram</h6>
            </div>

            <!-- YOUTUBE -->
            <div
              v-if="type == 'youtube'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Youtube'"
            >
              <i class="bi bi-youtube youtube-color mt--05 fa-xl"></i>
              <h6 class="text-muted ml-03">Youtube</h6>
            </div>

            <!-- TIKTOK -->
            <div
              v-if="type == 'tiktok'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Tiktok'"
            >
              <i class="bi bi-tiktok tiktok-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Tiktok</h6>
            </div>

            <!-- LINKEDIN -->
            <div
              v-if="type == 'linkedin'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Linkedin'"
            >
              <i class="bi bi-linkedin linkedin-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Linkedin</h6>
            </div>

            <!-- BLOG -->
            <div
              v-if="type == 'blog'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Blog'"
            >
              <i class="bi-rss-fill blog-color mt--05 fa-lg"></i>
              <h6 class="text-muted ml-03">Blog</h6>
            </div>

            <!-- FORUM -->
            <div
              v-if="type == 'forum'"
              class="mr-05 d-flex"
              v-b-tooltip.hover.v-dark="'Forum'"
            >
              <i
                class="fa-solid fa-comments forum-color fa-lg"
                style="margin-top: 7px"
              ></i>
              <h6 class="text-muted ml-03">Forum</h6>
            </div>

            <!-- ACCOUNT FOLLOWERS -->
            <div class="mr-05 d-flex" v-b-tooltip.hover.v-dark="'Follower'">
              <feather-icon
                icon="UsersIcon"
                size="18"
                style="margin-top: -3.5px"
              />
              <h6 class="text-muted ml-03">18,3K</h6>
            </div>

            <!-- ACCOUNT PERS -->
            <div class="mr-05 mt--05">
              <b-badge pill variant="warning">Pers</b-badge>
            </div>

            <!-- ACCOUNT SENTIMEN -->
            <div class="mr-05" style="margin-top: -6px">
              <!-- POSITIF IS SELECTED -->
              <b-dropdown
                v-if="selected == 'Positif'"
                size="sm"
                variant="outline-success"
                no-caret
              >
                <template #button-content>
                  <span
                    style="
                      z-index: 3;
                      position: absolute;
                      margin-top: -6px;
                      margin-left: -25px;
                    "
                  >
                    {{ selected }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      style="
                        z-index: 3;
                        position: absolute;
                        margin-left: 5px;
                        margin-top: -1px;
                      "
                    />
                  </span>
                </template>
                <b-dropdown-item @click="selected = 'Positif'"
                  >Positif</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Netral'"
                  >Netral</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Negatif'"
                  >Negatif</b-dropdown-item
                >
              </b-dropdown>

              <!-- NETRAL IS SELECTED -->
              <b-dropdown
                v-if="selected == 'Netral'"
                size="sm"
                variant="outline-primary"
                no-caret
              >
                <template #button-content>
                  <span
                    style="
                      z-index: 3;
                      position: absolute;
                      margin-top: -6px;
                      margin-left: -25px;
                    "
                  >
                    {{ selected }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      style="
                        z-index: 3;
                        position: absolute;
                        margin-left: 5px;
                        margin-top: -1px;
                      "
                    />
                  </span>
                </template>
                <b-dropdown-item @click="selected = 'Positif'"
                  >Positif</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Netral'"
                  >Netral</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Negatif'"
                  >Negatif</b-dropdown-item
                >
              </b-dropdown>

              <!-- NEGATIF IS SELECTED -->
              <b-dropdown
                v-if="selected == 'Negatif'"
                size="sm"
                variant="outline-danger"
                no-caret
              >
                <template #button-content>
                  <span
                    style="
                      z-index: 3;
                      position: absolute;
                      margin-top: -6px;
                      margin-left: -30px;
                    "
                  >
                    {{ selected }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      style="
                        z-index: 3;
                        position: absolute;
                        margin-left: 5px;
                        margin-top: -1px;
                      "
                    />
                  </span>
                </template>
                <b-dropdown-item @click="selected = 'Positif'"
                  >Positif</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Netral'"
                  >Netral</b-dropdown-item
                >
                <b-dropdown-item @click="selected = 'Negatif'"
                  >Negatif</b-dropdown-item
                >
              </b-dropdown>
            </div>

            <!-- ACCOUNT EMOTION -->
            <div class="mr-05 mt-05 d-flex clickable">
              <!-- NO EMOTION -->
              <i
                v-if="emotion == 0"
                class="fa-regular fa-face-meh-blank fa-lg text-secondary"
                v-b-tooltip.hover.v-dark="'Emotion'"
              ></i>

              <!-- SENANG EMOTION -->
              <i
                v-if="emotion == 1"
                class="fa-regular fa-face-grin-squint fa-lg text-success"
                v-b-tooltip.hover.v-dark="'Emotion'"
              ></i>

              <!-- TAKUT EMOTION -->
              <i
                v-if="emotion == 2"
                class="fa-regular fa-face-flushed fa-lg text-warning"
                v-b-tooltip.hover.v-dark="'Emotion'"
              ></i>

              <!-- SEDIH EMOTION -->
              <i
                v-if="emotion == 3"
                class="fa-regular fa-face-sad-tear fa-lg"
                style="color: #f26322"
                v-b-tooltip.hover.v-dark="'Emotion'"
              ></i>

              <!-- MARAH EMOTION -->
              <i
                v-if="emotion == 4"
                class="fa-regular fa-face-angry fa-lg text-danger"
                v-b-tooltip.hover.v-dark="'Emotion'"
              ></i>
            </div>

            <!-- ACCOUNT HIGHLIGHT -->
            <div class="mr-05 d-flex clickable">
              <feather-icon
                icon="StarIcon"
                v-b-tooltip.hover.v-dark="'Highlight'"
                size="18"
                style="margin-top: -2px"
              />
            </div>

            <!-- KUNJUNGI ACCOUNT -->
            <div class="mr-5 d-flex clickable">
              <feather-icon
                icon="ExternalLinkIcon"
                v-b-tooltip.hover.v-dark="'Kunjungi'"
                size="18"
                style="margin-top: -2px"
              />
            </div>
          </div>
        </b-media-body>
      </b-media>

      <!-- DELETE ACCOUNT -->
      <div class="d-flex mt-2">
        <div class="mr-05 ml-auto d-flex clickable">
          <feather-icon
            icon="TrashIcon"
            class="text-danger"
            v-b-tooltip.hover.v-dark="'Hapus'"
            size="18"
          />
        </div>

        <!-- DATE TIME OF NEWS -->
        <div class="d-flex" v-b-tooltip.hover.v-dark="'Tanggal Publish'">
          <feather-icon icon="CalendarIcon" size="18" />
          <h6 class="text-muted ml-03">08 Jan 2022 23.59</h6>
        </div>
      </div>
    </b-card-header>

    <!-- MEDSOS NEWS CONTENT -->
    <b-card-body>
      <!-- CONTENT TITLE -->
      <h5 class="apply-job-title">
        Need a designer to form branding essentials for my business.
      </h5>

      <!-- MAIN CONTENT -->
      <b-card-text class="text-justify">
        <p>{{ text }}</p>
      </b-card-text>

      <!-- META OF MEDIA -->
      <b-card-header
        style="margin-left: -20px; margin-top: -15px; margin-bottom: -2.5rem"
      >
        <div class="d-flex">
          <!-- LOVE META -->
          <div
            v-if="type !== 'facebook'"
            class="d-flex mr-05"
            v-b-tooltip.hover.v-dark
            title="Love/ Favourite"
          >
            <feather-icon icon="HeartIcon" />
            <h6 class="text-muted ml-03">18,3K</h6>
          </div>

          <!-- LIKE META -->
          <div
            v-if="type == 'facebook'"
            class="d-flex mr-05"
            v-b-tooltip.hover.v-dark
            title="Like"
          >
            <feather-icon icon="ThumbsUpIcon" />
            <h6 class="text-muted ml-03">18,3K</h6>
          </div>

          <!-- COMMENT META -->
          <div class="d-flex mr-05" v-b-tooltip.hover.v-dark title="Comment">
            <feather-icon icon="MessageCircleIcon" />
            <h6 class="text-muted ml-03">200</h6>
          </div>

          <!-- SHARE META -->
          <div
            v-if="type !== 'facebook'"
            class="d-flex mr-05"
            v-b-tooltip.hover.v-dark
            title="Retweet"
          >
            <feather-icon icon="RepeatIcon" />
            <h6 class="text-muted ml-03">912</h6>
          </div>
          <div
            v-if="type == 'facebook'"
            class="d-flex mr-05"
            v-b-tooltip.hover.v-dark
            title="Share"
          >
            <i class="fa-solid fa-share"></i>
            <h6 class="text-muted ml-03">18,3K</h6>
          </div>

          <!-- REACH META -->
          <div class="d-flex mr-05" v-b-tooltip.hover.v-dark title="Reach">
            <i class="fa-solid fa-bullhorn"></i>
            <h6 class="text-muted ml-03">912</h6>
          </div>

          <!-- ENGANGEMENT META -->
          <div
            class="d-flex mr-05"
            v-b-tooltip.hover.v-dark
            title="Engangement"
          >
            <feather-icon icon="Share2Icon" />
            <h6 class="text-muted ml-03">1,2M</h6>
          </div>

          <!-- DETAIL BUTTON -->
          <div
            style="position: absolute; z-index: 2; right: 0; margin-top: -2px"
          >
            <b-button
              v-if="text.length >= 200"
              variant="outline-primary"
              class="float-right"
              pill
              style="
                height: 5px;
                position: inherit;
                margin-right: 0.2rem;
                right: 0;
              "
            >
              <div class="d-flex" style="margin-top: -5px">
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                  class="mr-50"
                ></feather-icon>
                <small>Detail</small>
              </div>
            </b-button>
          </div>
        </div>

        <!-- KEYWORD OF MAIN CONTENT -->
        <div class="d-flex mr-auto">
          <div
            v-for="topik in topik"
            :key="topik.name"
            class="d-flex mr-05"
            style="margin-top: -4px"
          >
            <b-button variant="info" size="sm">
              <feather-icon icon="XCircleIcon" class="mr-50" />
              {{ topik.name }}
            </b-button>
          </div>
          <div v-if="topik.length > 2">
            <i class="clickable text-primary">{{ topik.length - 2 }} Lainnya</i>
          </div>
          <div style="margin-right: 7.5rem"></div>
        </div>
      </b-card-header>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  BFormGroup,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  name: "newsMedsosCard",
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BFormGroup,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      type: "facebook",
      selected: "Positif",
      emotion: 4,
      option: ["Positif", "Netral", "Negatif"],
      topik: [
        {
          name: "Pos Indonesia",
        },
        {
          name: "Pos Properti Indonesia",
        },
      ],
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi obcaecati, aperiam ipsum nesciunt aliquid vitae atque ut, voluptas dicta quidem, culpa praesentium aut ipsa labore autem a magni doloribus quod. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia excepturi eligendi iusto voluptatum? Qui, facilis cum? Fugit, eius recusandae at dolorem, quisquam nemo ipsam inventore corrupti earum enim, quo atque. ",
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
<style>
.twitter
  .card-header
  .media
  .media-body
  .d-flex
  .mr-05
  .dropdown
  .dropdown-toggle {
  border-radius: 50px !important;
  margin-top: 4px;
  height: 20px !important;
  width: 80px;
}
.custom-dropdown {
  margin-top: -10px !important;
}
</style>
