<template>
  <div>
    <!-- BACK BUTTON ON DRILL DOWN CHART -->
    <div v-if="drill" class="back-button">
      <b-button variant="outline-primary" size="sm" @click="drillTopBar()">
        <feather-icon icon="ArrowLeftIcon" class="mr-50" />
        <span class="align-middle">Kembali</span>
      </b-button>
    </div>

    <!-- BAR CHART -->
    <vue-apex-charts
      :height="370"
      type="bar"
      :options="config"
      :series="data_series"
      class="clickable"
    ></vue-apex-charts>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "drillDownBarChart",
  components: {
    VueApexCharts,
    BButton,
  },

  props: {
    data: Object,
  },

  data: function () {
    return {
      drill: false,
      index: null,
      data_series: [],
      data_categories: [],
      config: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (e, chart, opts) => {
              if (this.drill == false) {
                this.drillDownBar(opts.dataPointIndex);
              } else {
                this.barClick();
              }
            },
          },
        },
        colors: this.data.colors,
        plotOptions: {
          bar: {
            columnWidth: "30%",
            borderRadius: 7,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        stroke: {
          show: true,
          colors: ["transparent"],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: ["#A9A9A9"],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },

  mounted() {
    this.data_series = this.data.series;
    this.data_categories = this.data.categories;
    this.config = {
      ...this.config,
      ...{
        xaxis: {
          categories: this.data_categories,
        },
      },
    };
  },

  methods: {
    // SET DATA FOR DRILL BAR
    drillDownBar(index) {
      this.drill = true;
      this.index = index;
      this.config = {
        ...this.config,
        ...{
          xaxis: {
            categories: [this.data_categories[index]],
          },
        },
      };
      this.data_series = [
        {
          data: [this.data.series[0].data[index]],
        },
        {
          data: [this.data.series[1].data[index]],
        },
        {
          data: [this.data.series[2].data[index]],
        },
      ];
    },
    // SET DATA TO ORIGINAL DATA
    drillTopBar() {
      this.drill = false;
      this.data_series = this.data.series;
      this.config = {
        ...this.config,
        ...{
          xaxis: {
            categories: this.data.categories,
          },
        },
      };
    },
    // GET ITEM WHEN BAR CLICKED
    barClick() {
      this.$router.push("/mention");
    },
  },
};
</script>
<style scoped>
.back-button {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 2rem;
  margin-top: 2rem;
}
</style>
