<template>
  <b-card class="h-100" no-body>
    <b-card-header class="mb-2">
      <b-card-title>Total Data Media Konvensional</b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-for="medkon in medkon"
        :key="medkon.name"
        class="klik clickable"
        @click="redirectHandle()"
      >
        <div class="d-flex align-items-center pxy">
          <b-media-aside>
            <!-- MEDIA AVATAR -->
            <b-avatar rounded size="35" variant="light-primary">
              <!-- MEDIA ONLINE -->
              <i
                v-if="medkon.name == 'Media Online'"
                class="bi-globe media-online-color fa-xl"
              ></i>

              <!-- MEDIA CETAK -->
              <i
                v-else-if="medkon.name == 'Media Cetak'"
                class="bi-newspaper media-cetak-color fa-xl"
              ></i>

              <!-- MEDIA ELEKTRONIK -->
              <i
                v-else-if="medkon.name == 'Media Elektronik'"
                class="bi-display media-elektronik-color fa-xl"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <div class="d-flex">
              <!-- MEDIA NAME -->
              <div class="mr-auto">
                {{ medkon.name }}
              </div>

              <!-- MEDIA DATA VALUE -->
              <div class="font-weight-bolder ml-auto">
                {{ medkon.data }}
              </div>
            </div>

            <!-- MEDIA PROGRESS BAR -->
            <b-progress :max="max">
              <!-- MEDIA ONLINE -->
              <b-progress-bar
                v-if="medkon.name == 'Media Online'"
                :value="medkon.value"
                :style="{ 'background-color': '#28C76F' }"
              >
                <strong>{{ medkon.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- MEDIA CETAK -->
              <b-progress-bar
                v-if="medkon.name == 'Media Cetak'"
                :value="medkon.value"
                :style="{ 'background-color': '#82868B' }"
              >
                <strong>{{ medkon.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- MEDIA ELEKTRONIK -->
              <b-progress-bar
                v-if="medkon.name == 'Media Elektronik'"
                :value="medkon.value"
                :style="{ 'background-color': '#E83E8C' }"
              >
                <strong>{{ medkon.value.toFixed(2) }} %</strong>
              </b-progress-bar>
            </b-progress>
          </b-media-body>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  name: "totalDataMedkon",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BProgress,
    BProgressBar,
  },

  data() {
    return {
      max: 100,
      medkon: [
        {
          name: "Media Online",
          data: "83,5k",
          value: 90,
        },
        {
          name: "Media Cetak",
          data: "83,5k",
          value: 75,
        },
        {
          name: "Media Elektronik",
          data: "83,5k",
          value: 78,
        },
      ],
    };
  },

  methods: {
    redirectHandle() {
      this.$router.push("/mention");
    },
  },
};
</script>
<style scoped>
.klik :hover {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.pxy {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.dark-layout * .klik :hover {
  background-color: #161d31;
}
</style>