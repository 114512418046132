<template>
  <b-card class="h-100" no-body>
    <b-card-header class="mb-2">
      <b-card-title>Total Data Media Sosial</b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-for="medsos in medsos"
        :key="medsos.name"
        class="klik clickable"
        @click="redirectHandle()"
      >
        <div class="d-flex align-items-center pxy">
          <b-media-aside>
            <!-- MEDIA AVATAR -->
            <b-avatar rounded size="35" variant="light-primary">
              <!-- TWITTER -->
              <i
                v-if="medsos.name == 'Twitter'"
                class="bi-twitter twitter-color fa-xl"
              ></i>

              <!-- FACEBOOK -->
              <i
                v-else-if="medsos.name == 'Facebook'"
                class="bi-facebook facebook-color fa-xl"
              ></i>

              <!-- INSTAGRAM -->
              <i
                v-else-if="medsos.name == 'Instagram'"
                class="bi-instagram instagram-color fa-xl"
              ></i>

              <!-- TIKTOK -->
              <i
                v-else-if="medsos.name == 'Tiktok'"
                class="bi-tiktok tiktok-color fa-xl"
              ></i>

              <!-- YOUTUBE -->
              <i
                v-else-if="medsos.name == 'Youtube'"
                class="bi-youtube youtube-color fa-xl"
              ></i>

              <!-- LINKEDIN -->
              <i
                v-else-if="medsos.name == 'Linkedin'"
                class="bi-linkedin linkedin-color fa-xl"
              ></i>

              <!-- FORUM -->
              <i
                v-else-if="medsos.name == 'Forum'"
                class="fa-solid fa-comments forum-color fa-xl"
              ></i>

              <!-- BLOG -->
              <i
                v-else-if="medsos.name == 'Blog'"
                class="bi-rss-fill blog-color fa-xl"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <div class="d-flex">
              <!-- MEDIA NAME -->
              <div class="mr-auto">
                {{ medsos.name }}
              </div>

              <!-- MEDIA DATA VALUE -->
              <div class="font-weight-bolder ml-auto">
                {{ medsos.data }}
              </div>
            </div>

            <!-- MEDIA PROGRESS BAR -->
            <b-progress :max="max">
              <!-- TWITTER -->
              <b-progress-bar
                v-if="medsos.name == 'Twitter'"
                :value="medsos.value"
                :style="{ 'background-color': '#00ACEE' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- FACEBOOK -->
              <b-progress-bar
                v-if="medsos.name == 'Facebook'"
                :value="medsos.value"
                :style="{ 'background-color': '#467FFF' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- INSTAGRAM -->
              <b-progress-bar
                v-if="medsos.name == 'Instagram'"
                :value="medsos.value"
                :style="{ 'background-color': '#EA5455' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- TIKTOK -->
              <b-progress-bar
                v-if="medsos.name == 'Tiktok'"
                :value="medsos.value"
                :style="{ 'background-color': '#000000' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- YOUTUBE -->
              <b-progress-bar
                v-if="medsos.name == 'Youtube'"
                :value="medsos.value"
                :style="{ 'background-color': '#FF0000' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- LINKEDIN -->
              <b-progress-bar
                v-if="medsos.name == 'Linkedin'"
                :value="medsos.value"
                :style="{ 'background-color': '#0A66C2' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- FORUM -->
              <b-progress-bar
                v-if="medsos.name == 'Forum'"
                :value="medsos.value"
                :style="{ 'background-color': '#4B4B4B' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>

              <!-- BLOG -->
              <b-progress-bar
                v-if="medsos.name == 'Blog'"
                :value="medsos.value"
                :style="{ 'background-color': '#FF9F43' }"
              >
                <strong>{{ medsos.value.toFixed(2) }} %</strong>
              </b-progress-bar>
            </b-progress>
          </b-media-body>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  name: "totalDataMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BProgress,
    BProgressBar,
  },

  data() {
    return {
      max: 100,
      medsos: [
        {
          name: "Twitter",
          data: "83,5k",
          value: 90,
        },
        {
          name: "Facebook",
          data: "83,5k",
          value: 75,
        },
        {
          name: "Instagram",
          data: "83,5k",
          value: 78,
        },
        {
          name: "Tiktok",
          data: "83,5k",
          value: 80,
        },
        {
          name: "Youtube",
          data: "83,5k",
          value: 65,
        },
        {
          name: "Linkedin",
          data: "83,5k",
          value: 78,
        },
        {
          name: "Forum",
          data: "83,5k",
          value: 78,
        },
        {
          name: "Blog",
          data: "83,5k",
          value: 89,
        },
      ],
    };
  },

  methods: {
    redirectHandle() {
      this.$router.push("/mention");
    },
  },
};
</script>
<style scoped>
.klik :hover {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.pxy {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.dark-layout * .klik :hover {
  background-color: #161d31;
}
</style>