<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Kembali"
    title-tag="h4"
    ok-variant="info"
    ok-only
    :size="data.size"
  >
    <!-- FORM SEARCH -->
    <b-input-group
      class="input-group-merge mt-2 mb-1 ml-auto"
      style="width: 45%"
    >
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" class="text-primary" />
      </b-input-group-prepend>
      <b-form-input placeholder="Search" v-model="data.table_data.filter" />
    </b-input-group>

    <!-- TABLE -->
    <dataTable
      :data="data.table_data"
      style="margin-left: -20px; margin-right: -20px"
    />
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import dataTable from "./dataTable.vue";

export default {
  name: "tableModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BButton,
    vSelect,
    VBModal,
    dataTable,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      data_page: [
        {
          id: 1,
          title: "Facebook Page 1",
        },
        {
          id: 2,
          title: "Facebook Page 2",
        },
        {
          id: 3,
          title: "Facebook Page 3",
        },
        {
          id: 4,
          title: "Facebook Page 4",
        },
        {
          id: 5,
          title: "Facebook Page 5",
        },
      ],
    };
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>