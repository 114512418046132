<template>
  <div>
    <!-- BAR CHART -->
    <vue-apex-charts
      type="bar"
      height="370"
      :options="config"
      :series="data.series"
      class="clickable"
    />

    <!-- TABLE MODAL -->
    <tableModal :data="table_modal_data" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import tableModal from "./tableModal.vue";

export default {
  name: "horizontalBarChart",
  components: {
    VueApexCharts,
    tableModal,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      table_modal_data: {
        title: "News Trend",
        type: "Facebook",
        size: "lg",
        id: "table-newstrend",
        table_data: {
          row: 5,
          fields: [
            {
              key: "media",
              label: "Media",
              thClass: "text-left",
              tdClass: "text-left",
              sortable: true,
              thStyle: { paddingRight: "2rem" },
            },
            {
              key: "positif",
              label: "Positif",
              thClass: "text-center",
              tdClass: "text-center",
              sortable: true,
              thStyle: { width: "15%", paddingRight: "2rem" },
            },
            {
              key: "netral",
              label: "Netral",
              thClass: "text-center",
              tdClass: "text-center",
              sortable: true,
              thStyle: { width: "15%", paddingRight: "2rem" },
            },
            {
              key: "negatif",
              label: "Negatif",
              thClass: "text-center",
              tdClass: "text-center",
              sortable: true,
              thStyle: { width: "15%", paddingRight: "2rem" },
            },
            {
              key: "jumlah",
              label: "Jumlah",
              thClass: "text-center",
              tdClass: "text-center",
              sortable: true,
              thStyle: { width: "15%", paddingRight: "2rem" },
            },
          ],
          items: [
            {
              id: 1,
              media: "Twitter",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 2,
              media: "Media Elektronik",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 3,
              media: "Media Cetak",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 4,
              media: "Youtube",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 5,
              media: "Youtube",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 6,
              media: "Youtube",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 7,
              media: "Facebook",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 8,
              media: "Facebook",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 9,
              media: "Facebook",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
            {
              id: 10,
              media: "Facebook",
              positif: 5,
              netral: 10,
              negatif: 6,
              jumlah: 21,
            },
          ],
        },
      },
      config: {
        chart: {
          fontFamily: "Montserrat",
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              console.log(config.dataPointIndex);
              this.barClick();
            },
          },
        },
        colors: this.data.colors,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "30%",
            borderRadius: 7,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        xaxis: {
          categories: this.data.categories,
          labels: {
            show: true,
            offsetX: 0,
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "left",
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontWeight: 600,
            },
          },
        },
      },
    };
  },

  methods: {
    // GET ITEM WHEN BAR CLICKED
    barClick() {
      this.$bvModal.show(this.table_modal_data.id);
    },
  },
};
</script>
