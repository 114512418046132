<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> News Trend </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- HORIZONTAL BAR CHART COMPONENT -->
      <horizontalBarChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import horizontalBarChart from "../usable/horizontalBarChart.vue";

export default {
  name: "newsTrend",
  components: {
    horizontalBarChart,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },

  data() {
    return {
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: [
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",
          "Penyebaran Covid 19 semakin meluas",

        ],
        series: [
          {
            name: "Positif",
            data: [90, 120, 55, 100, 80,100,100,100,100,100],
          },
          {
            name: "Netral",
            data: [85, 100, 30, 40, 95,100,100,100,100,100],
          },
          {
            name: "Negatif",
            data: [85, 100, 30, 40, 95,100,100,100,100,100],
          },
        ],
      },
    };
  },
};
</script>
