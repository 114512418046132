<template>
  <div class="dashboard">
    <!-- ROW 1 -->
    <div class="row" style="margin-bottom: 2rem">
      <!-- MEDIA KONVENSIONAL -->
      <div class="col-md-6">
        <analyticMedkon />
      </div>

      <!-- MEDIA SOSIAL -->
      <div class="col-md-6">
        <analyticMedsos />
      </div>
    </div>

    <!-- ROW 2 -->
    <div class="row" style="margin-bottom: 2rem">
      <!-- NEWS TREND -->
      <div class="col-md-12">
        <newsTrend />
      </div>
    </div>

    <!-- ROW 3 -->
    <div class="row" style="margin-bottom: 2rem">
      <!-- SENTIMEN POSTING MEDIA SOSIAL -->
      <div class="col-md-4">
        <sentimenMedsos />
      </div>

      <!-- TOPIK MEDIA SOSIAL -->
      <div class="col-md-5">
        <topikMedsos />
      </div>

      <!-- TOTAL DATA MEDIA SOSIAL -->
      <div class="col-md-3">
        <totalDataMedsos />
      </div>
    </div>

    <!-- ROW 4 -->
    <div class="row" style="margin-bottom: 2rem">
      <!-- SENTIMEN POSTING MEDIA KONVENSIONAL -->
      <div class="col-md-4">
        <sentimenMedkon />
      </div>

      <!-- TOPIK MEDIA KONVENSIONAL -->
      <div class="col-md-5">
        <topikMedkon />
      </div>

      <!-- TOTAL DATA MEDIA KONVENSIONAL -->
      <div class="col-md-3">
        <totalDataMedkon />
      </div>
    </div>

    <!-- HEADER TOP POSTING MEDIA KONVENSIONAL -->
    <div class="d-flex mb-2">
      <h3>Top Posting Media Konvensional</h3>
      <div class="h5 ml-auto text-primary">
        <router-link to="/">
          Lihat Lebih Banyak
          <span><feather-icon icon="ChevronRightIcon" /></span>
          <span style="margin-left: -8px"
            ><feather-icon icon="ChevronRightIcon"
          /></span>
        </router-link>
      </div>
    </div>

    <!-- ITEM TOP POSTING MEDIA KONVENSIONAL -->
    <div class="scrolling-wrapper row flex-row flex-nowrap mb-2">
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
    </div>

    <!-- HEADER TOP POSTING MEDIA SOSIAL -->
    <div class="d-flex mb-2">
      <h3>Top Posting Media Sosial</h3>
      <div class="h5 ml-auto text-primary">
        <router-link to="/">
          Lihat Lebih Banyak
          <span><feather-icon icon="ChevronRightIcon" /></span>
          <span style="margin-left: -8px"
            ><feather-icon icon="ChevronRightIcon"
          /></span>
        </router-link>
      </div>
    </div>

    <!-- ITEM TOP POSTING MEDIA SOSIAL -->
    <div class="scrolling-wrapper row flex-row flex-nowrap mb-2">
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
      <div class="col-md-6 mb-1">
        <newsMedsosCard />
      </div>
    </div>
  </div>
</template>

<script>
import analyticMedsos from "@/components/dashboard/analyticMedsos.vue";
import analyticMedkon from "@/components/dashboard/analyticMedkon.vue";
import newsTrend from "@/components/dashboard/newsTrend.vue";
import sentimenMedsos from "@/components/dashboard/sentimenMedsos.vue";
import topikMedsos from "@/components/dashboard/topikMedsos.vue";
import totalDataMedsos from "@/components/dashboard/totalDataMedsos.vue";
import sentimenMedkon from "@/components/dashboard/sentimenMedkon.vue";
import topikMedkon from "@/components/dashboard/topikMedkon.vue";
import totalDataMedkon from "@/components/dashboard/totalDataMedkon.vue";
import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";

export default {
  name: "Dashboard",
  components: {
    analyticMedsos,
    analyticMedkon,
    newsTrend,
    sentimenMedsos,
    topikMedsos,
    totalDataMedsos,
    sentimenMedkon,
    topikMedkon,
    totalDataMedkon,
    newsMedsosCard,
  },

  data() {
    return {
      title: "Dashboard | Online Media Monitoring",
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>
<style scoped>
.scrolling-wrapper {
  overflow-x: auto;
}
.scrolling-wrapper::-webkit-scrollbar {
  height: 10px;
}
.scrolling-wrapper::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}

.scrolling-wrapper::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.scrolling-wrapper::-webkit-scrollbar-corner {
  background-color: #d3eeff;
}
.dark-layout * .scrolling-wrapper::-webkit-scrollbar-thumb {
  background-color: #343b51 !important;
  border-radius: 10px;
}
.dark-layout * .scrolling-wrapper::-webkit-scrollbar-track {
  background-color: #202940 !important;
  border-radius: 10px;
}
.dark-layout * .scrolling-wrapper::-webkit-scrollbar-corner {
  background-color: #343b51 !important;
  border-radius: 10px;
}
</style>
