<template>
  <vue-apex-charts
    type="bar"
    :height="370"
    :options="config"
    :series="data.series"
    class="clickable"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "dataBarChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      config: {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.barClick(opts);
            },
          },
        },
        colors: this.data.colors,
        plotOptions: {
          bar: {
            columnWidth: "30%",
            borderRadius: 7,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
        },
        stroke: {
          show: true,
          colors: ["transparent"],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.data.categories,
          labels: {
            show: true,
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: ["#A9A9A9"],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },

  methods: {
    // GET ITEM WHEN BAR CLIKED
    barClick(option) {
      this.$router.push("/mention");
    },
  },
};
</script>
