<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Topik Media Sosial </b-card-title>
      <!-- SWITCH BUTTON -->
      <div class="d-flex align-items-center">
        <div>
          <b-form-checkbox
            v-model="checked"
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="ServerIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BarChart2Icon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </b-card-header>

    <!-- IF SWITCH BUTTON IS NOT CHECKED -->
    <div v-if="!checked" class="mx-1">
      <!-- BAR CHART COMPONENT -->
      <dataBarChart :data="chart_data" />
    </div>

    <!-- IF SWITCH BUTTON IS CHECKED -->
    <div v-else>
      <!-- SEARCH FORM -->
      <b-input-group
        class="input-group-merge ml-auto mb-1 mr-2 mt-2"
        style="width: 40%"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-primary" />
        </b-input-group-prepend>
        <b-form-input placeholder="Cari" v-model="table_data.filter" />
      </b-input-group>

      <!-- TABLE COMPONENT -->
      <dataTable :data="table_data" />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import dataTable from "../usable/dataTable.vue";
import dataBarChart from "../usable/dataBarChart.vue";

export default {
  name: "topikMedsos",
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BCardTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    dataTable,
    dataBarChart,
  },

  data() {
    return {
      checked: false,
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: [
          "7/12",
          "8/12",
          "9/12",
          "10/12",
          "11/12",
          "12/12",
          "13/12",
          "14/12",
          "15/12",
          "16/12",
        ],
        series: [
          {
            name: "Positif",
            data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
          },
          {
            name: "Netral",
            data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
          },
          {
            name: "Negatif",
            data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
          },
        ],
      },
      table_data: {
        row: 5,
        filter: null,
        fields: [
          {
            key: "Topik",
            label: "Topik",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "Positif",
            label: "Positif",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Netral",
            label: "Netral",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Negatif",
            label: "Negatif",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            Topik: "Badai",
            Positif: 511,
            Netral: 400,
            Negatif: 350,
          },
          {
            Topik: "Kemarau",
            Positif: 250,
            Netral: 200,
            Negatif: 350,
          },
          {
            Topik: "Hujan",
            Positif: 511,
            Netral: 65,
            Negatif: 200,
          },
          {
            Topik: "Kriminal",
            Positif: 5,
            Netral: 5,
            Negatif: 500,
          },
          {
            Topik: "Selebrity",
            Positif: 340,
            Netral: 67,
            Negatif: 400,
          },
        ],
      },
    };
  },
};
</script>
