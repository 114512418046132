<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title>Media Konvensional</b-card-title>
      <feather-icon
        icon="HelpCircleIcon"
        size="18"
        class="text-muted cursor-pointer"
        v-b-tooltip.hover.v-dark
        title="Help"
      />
    </b-card-header>
    <b-card-body>
      <div class="d-flex justify-content-end mb-2">
        <!-- PERIODE INI VALUE -->
        <div class="mr-2">
          <b-card-text class="mb-50"> Periode Ini </b-card-text>
          <h3 class="font-weight-bolder">
            <span>{{ periode_ini }}</span>
          </h3>
        </div>

        <!-- PERIODE LALU VALUE-->
        <div>
          <b-card-text class="mb-50"> Periode Lalu </b-card-text>
          <h3 class="font-weight-bolder">
            <span>{{ periode_lalu }}</span>
          </h3>
        </div>
      </div>

      <!-- LINE CHART COMPONENT -->
      <lineChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";
import lineChart from "../usable/lineChart.vue";

export default {
  name: "analyticMedkon",
  components: {
    lineChart,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VBTooltip,
  },

  data() {
    return {
      periode_ini: 10450000,
      periode_lalu: 126005000,
      chart_data: {
        colors: ["#28C76F", "#82868B", "#E83E8C"],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Media Online",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Media Cetak",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Media Elektronik",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },

  mounted() {
    this.periode_ini = this.changeNumber(this.periode_ini, 2);
    this.periode_lalu = this.changeNumber(this.periode_lalu, 2);
  },

  methods: {
    changeNumber(number, dec) {
      dec = Math.pow(10, dec);
      let satuan = ["K", "M", "B", "T"];
      for (let i = satuan.length - 1; i >= 0; i--) {
        let size = Math.pow(10, (i + 1) * 3);
        if (size <= number) {
          number = Math.round((number * dec) / size) / dec;
          number += satuan[i];
          break;
        }
      }
      return number;
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
