<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title>Media Sosial</b-card-title>
      <feather-icon
        icon="HelpCircleIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>
    <b-card-body>
      <div class="d-flex mb-2">
        <div class="d-flex mr-auto">
          <!-- REACH VALUE -->
          <div class="mr-2">
            <b-card-text class="mb-50"> Reach </b-card-text>
            <h3 class="font-weight-bolder">
              <span>{{ reach }}</span>
            </h3>
          </div>

          <!-- ENGAGEMENT VALUE -->
          <div>
            <b-card-text class="mb-50"> Engagement </b-card-text>
            <h3 class="font-weight-bolder">
              <span>{{ engagement }}</span>
            </h3>
          </div>
        </div>
        <div class="d-flex ml-auto">
          <!-- PERIODE INI VALUE -->
          <div class="mr-2">
            <b-card-text class="mb-50"> Periode Ini </b-card-text>
            <h3 class="font-weight-bolder">
              <span>{{ periode_ini }}</span>
            </h3>
          </div>

          <!-- PERIODE LALU VALUE -->
          <div>
            <b-card-text class="mb-50"> Periode Lalu </b-card-text>
            <h3 class="font-weight-bolder">
              <span>{{ periode_lalu }}</span>
            </h3>
          </div>
        </div>
      </div>

      <!-- LINE CHART COMPONENT -->
      <lineChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
} from "bootstrap-vue";
import lineChart from "../usable/lineChart.vue";

export default {
  name: "analyticMedsos",
  components: {
    lineChart,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },

  data() {
    return {
      reach: 150000,
      engagement: 1500000,
      periode_ini: 10050000,
      periode_lalu: 106005000,
      chart_data: {
        colors: [
          "#00ACEE",
          "#467FFF",
          "#EA5455",
          "#000000",
          "#FF0000",
          "#0A66C2",
          "#4B4B4B",
          "#FF9F43",
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Twitter",
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Facebook",
            data: [43000, 41000, 45500, 42600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Instagram",
            data: [41000, 45000, 45500, 43600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Tiktok",
            data: [42000, 47000, 45500, 45600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Youtube",
            data: [47000, 49000, 45500, 46600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Linkedin",
            data: [49000, 44000, 45500, 49600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Forum",
            data: [43000, 42000, 45500, 42600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Blog",
            data: [42000, 45000, 45500, 46600, 43000, 46500, 45000, 47000],
          },
        ],
      },
    };
  },

  mounted() {
    this.reach = this.changeNumber(this.reach, 2);
    this.engagement = this.changeNumber(this.engagement, 2);
    this.periode_ini = this.changeNumber(this.periode_ini, 2);
    this.periode_lalu = this.changeNumber(this.periode_lalu, 2);
  },

  methods: {
    changeNumber(number, dec) {
      dec = Math.pow(10, dec);
      let satuan = ["K", "M", "B", "T"];
      for (let i = satuan.length - 1; i >= 0; i--) {
        let size = Math.pow(10, (i + 1) * 3);
        if (size <= number) {
          number = Math.round((number * dec) / size) / dec;
          number += satuan[i];
          break;
        }
      }
      return number;
    },
  },
};
</script>
