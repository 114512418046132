<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title>Sentimen Posting Media Sosial</b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- PIE CHART COMPONENT -->
      <pieChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BProgress,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import pieChart from "../usable/pieChart.vue";

export default {
  name: "sentimenMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    pieChart,
    BProgress,
  },

  data() {
    return {
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        legend: true,
        labels: ["Positif", "Netral", "Negatif"],
        series: [5, 6, 7],
      },
    };
  },
};
</script>
