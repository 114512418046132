<template>
  <vue-apex-charts
    type="pie"
    :height="390"
    :options="config"
    :series="data.series"
    class="clickable"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "pieChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      config: {
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (e, chart, opts) => {
              this.chartClick(opts);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: this.data.labels,
        legend: {
          show: this.data.legend,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: ["#A9A9A9"],
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        stroke: {
          width: 3,
        },
        colors: this.data.colors,
      },
    };
  },

  methods: {
    // GET ITEM WHEN CHART CLICKED
    chartClick(option) {
      this.$router.push("/mention");
    },
  },
};
</script>
