<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="per_page"
      :current-page="current_page"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      sticky-header="700px"
      show-empty
    >
      <!-- SHOW EMPTY DATA -->
      <template #empty="scope">
        <center>
          <h6>{{ scope.emptyText }}</h6>
        </center>
      </template>

      <!-- SHOW EMPTY FILTERED -->
      <template #emptyfiltered="scope">
        <center>
          <h6>{{ scope.emptyFilteredText }}</h6>
        </center>
      </template>

      <!-- CELL NO -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- CELL PLATFORM -->
      <template #cell(platform)="data">
        <b-avatar variant="light-primary">
          <!-- TWITTER ICON -->
          <i
            v-if="data.value == 'twitter'"
            class="bi bi-twitter twitter-color fa-lg"
          ></i>

          <!-- FACEBOOK ICON -->
          <i
            v-if="data.value == 'facebook'"
            class="bi bi-facebook facebook-color fa-lg"
          ></i>

          <!-- INSTAGRAM ICON -->
          <i
            v-if="data.value == 'instagram'"
            class="bi bi-instagram instagram-color fa-lg"
          ></i>

          <!-- YOUTUBE ICON -->
          <i
            v-if="data.value == 'youtube'"
            class="bi bi-youtube youtube-color fa-lg"
          ></i>

          <!-- LINKEDIN ICON -->
          <i
            v-if="data.value == 'linkedin'"
            class="bi bi-linkedin linkedin-color fa-lg"
          ></i>

          <!-- FORUM ICON -->
          <i
            v-if="data.value == 'forum'"
            class="fa-solid fa-comments forum-color fa-lg"
          ></i>

          <!-- BLOG ICON -->
          <i
            v-if="data.value == 'blog'"
            class="bi bi-rss-fill blog-color fa-lg"
          ></i>

          <!-- TIKTOK ICON -->
          <i
            v-if="data.value == 'tiktok'"
            class="bi bi-tiktok tiktok-color fa-lg"
          ></i>

          <!-- MEDIA ONLINE ICON -->
          <i
            v-if="data.value == 'media online'"
            class="bi bi-globe media-online-color"
          >
          </i>

          <!-- MEDIA CETAK ICON -->
          <i
            v-else-if="data.value == 'media cetak'"
            class="bi bi-newspaper media-cetak-color"
          >
          </i>

          <!-- MEDIA ELEKTRONIK ICON -->
          <i
            v-else-if="data.value == 'media elektronik'"
            class="bi bi-display media-elektronik-color"
          >
          </i>
        </b-avatar>
      </template>
    </b-table>

    <div class="d-flex align-items-center mt-2">
      <!-- ROW INFORMATION AND SELECT ROW -->
      <div class="d-flex align-items-center mr-auto ml-2">
        <div>Showing {{ current_page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select v-model="per_page" :options="page_options" />
        </div>
        <div>
          of
          {{ data.items.length }} Entries
        </div>
      </div>

      <!-- PAGINATION -->
      <div class="ml-auto mt-1 mr-2">
        <b-pagination
          v-model="current_page"
          :total-rows="data.items.length"
          :per-page="per_page"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BFormSelect, BAvatar, BPagination } from "bootstrap-vue";

export default {
  name: "dataTable",
  components: {
    BTable,
    BFormSelect,
    BPagination,
    BAvatar,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      per_page: this.data.row,
      page_options: [3, 5, 10],
      current_page: 1,
    };
  },
};
</script>
 